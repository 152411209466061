import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { MainSection, SingleSection, SingleTitle } from './components/styleds';

function Contact() {
    return (
        <div className="Contact">
            <Header />
            <MainSection>
                <SingleSection>
                    <SingleTitle>Contattaci</SingleTitle>
                    <hr></hr>
                    <p>Se vuoi contattarci, scrivi all'indirizzo mail standard o alla pec:</p>
                    <p><a href='mailto:amicidivillasangiuseppe@gmail.com'>amicidivillasangiuseppe @ gmail.com</a></p>
                    <p><a href='mailto:aamicidivsgiuseppe@namirial.pec
'>amicidivsgiuseppe @ namirial.pec
                    </a></p>
                    <p>Oppure visita/unisciti al nostro gruppo Facebook <a href='https://www.facebook.com/people/Amici-di-Villa-San-Giuseppe-RC/61556904324710/' target='_blank'>cliccando qui</a>. Risponderemo appena possibile!</p>
                </SingleSection>
                <SingleSection className='img-banner'>
                </SingleSection>
            </MainSection>
            <Footer />
        </div>
    );
}

export default Contact;
