import styled from "styled-components";

export const MainFooter = styled.div`
    background-color: #fbfbfb;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    bottom: 0;

    .fb-logo {
        width: 50px;
        display: flex;
        margin: 0 auto;
        margin-top: 0.8rem;
        cursor: pointer;
    }

    .fb-logo:hover {
        opacity: 0.8;
    }
`;

export const MainLogo = styled.img`
    width: 100px;
    transition: opacity 0.3s ease;
    position: relative;
    display: flex;
    margin: 0 auto;
    opacity: 0.2;
`;

export const FooterDescription = styled.div`
    width: 60%;
    font-size: 13px;
    margin: 0 auto;
    margin-top: 1rem;

    a {
        color: #e8751e;
        text-decoration: none;
    }

    .mail-banner {
        margin-top: 0.5rem;
    }

    .type-banner {
        margin-top: 0.5rem;
    }

    .location-banner {
        margin-top: 0.5rem;
    }
`;