import styled from "styled-components";

// Default theme
import '@splidejs/react-splide/css';

// or other themes
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/sea-green';

// or only core styles
import '@splidejs/react-splide/css/core';

export const MainHome = styled.div`
    width: 100%;
    height: 100%;

    hr {
        border: 0px;
        border-top: 1px solid #e8751e;
        width: 80%;
    }

    .fullscreen-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .fullscreen-image {
        cursor: pointer;
        max-width: 90%; /* Per mantenere l'aspetto originale dell'immagine */
        max-height: 90%;
        object-fit: contain;
        z-index: 10; /* Assicura che l'immagine sia sopra il div overlay */
    } 

    .splide {
        height: 30rem;
        display: flex;
        background: #181818;

        img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .splide__arrow svg {
            fill: #e8741e;
        }

        .splide__pagination__page.is-active, .splide__pagination__page:hover {
            background: #e8741e;
        }

    }

    .box {
        width: 100%;
        height: 31rem;
        background: url('./waves.svg');
        background-size: cover;

        @media only screen and (max-width: 900px) {
            margin-top: 7rem;
            height: 18rem;
        }
    
        @media only screen and (max-width: 700px) {
            margin-top: 7rem;
            height: 14rem;
        }
    
        @media only screen and (max-width: 500px) {
            height: 8rem;
        }
    
        @media only screen and (max-width: 400px) {
            height: 8rem;
        }
    
        @media only screen and (max-width: 300px) {
            margin-top: 12rem;
        }
    }
`;

export const TopBackground = styled.div`
    width: 100%;
    height: 30rem;
    background-image: url('./gradient-bg.svg');
    background-size: cover;
    background-position: center;

    #background_video {
        position: fixed;
        z-index: 0;
        left: 0;

        @media only screen and (min-width: 2000px) {
            display: none;
        }

        @media only screen and (max-width: 900px) {
            width: 120% !important;
        }

        @media only screen and (max-width: 750px) {
            width: 150% !important;
        }

        @media only screen and (max-width: 650px) {
            width: 170% !important;
            left: -320px;
        }
    
        @media only screen and (max-width: 550px) {
            width: 200% !important;
            left: -350px;
        }
    
        @media only screen and (max-width: 500px) {
            width: 260% !important;
            left: -390px;
        }

        @media only screen and (max-width: 400px) {
            width: 270% !important;
            left: -400px;
        }
    
        @media only screen and (max-width: 350px) {
            width: 280% !important;
            left: -450px;
        }
    
        @media only screen and (max-width: 320px) {
            width: 350% !important;
            left: -400px;
        }
    }

    &:before {
        content: '';
        background-color: rgba(232, 117, 30, 0.1);
        filter: blur(2);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 30rem;
        z-index: 1;
    }
`;

export const BgBanner = styled.div`
    width: 50%;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: table;
    margin: 0 auto;
    border: 2px solid rgba(255,255,255,0.3);
    border-radius: 10px;
    transition: border 0.5s;
    color: white;
    z-index: 2;

    &:hover {
        border: 2px solid rgba(255, 255, 255, 1);
        background: rgba(255, 255, 255, 0.1);
    }

    @media only screen and (max-width: 900px) {
        top: 52%;
    }

    @media only screen and (max-width: 900px) {
        top: 55%;
    }

    @media only screen and (max-width: 600px) {
        top: 60%;
    }

    @media only screen and (max-width: 300px) {
        top: 70%;
    }

    @media only screen and (max-width: 250) {
        top: 72%;
    }
`;

export const BgTitle = styled.h1`
    margin: 0.1rem;
    font-size: 1.5em;
    font-family: Sacramento Regular;
    font-weight: 100;
`

export const BgTitle2 = styled.h1`
    margin: 0.1rem;
    font-size: 2.5em;
    font-family: Sacramento Regular;
`

export const InitialSection = styled.div`
    width: 100%;
    height: 21rem;
    background: white;
    position: relative;
    z-index: 5;

    @media only screen and (max-width: 900px) {
        height: 28rem;
    }

    @media only screen and (max-width: 700px) {
        height: 30rem;
    }

    @media only screen and (max-width: 500px) {
        height: 35rem;
    }

    @media only screen and (max-width: 400px) {
        height: 47rem;
    }

    @media only screen and (max-width: 300px) {
        height: 43rem;
    }
`;

export const MapsSection = styled.div`
    width: 100%;
    height: 20rem;
`

export const InitialSvg = styled.img`
    width: 50%;
    float: right;
    position: relative;
    margin-top: 1rem;
    margin-right: 5rem;
    transition: width 2s;

    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @media only screen and (max-width: 5500px) {
        width: 15%;
        &:hover {
            width: 20%;
        }
    }

    @media only screen and (max-width: 2000px) {
        width: 25%;
        &:hover {
            width: 30%;
        }
    }

    @media only screen and (max-width: 1500px) {
        width: 27%;
        &:hover {
            width: 32%;
        }
    }

    @media only screen and (max-width: 900px) {
        top: 30%;
        margin-top: 0px;
        display: table;
        margin: 0 auto;
        float: none;
        width: 45%;
        &:hover {
            width: 50%;
        }
    }

    @media only screen and (max-width: 500px) {
        top: 15%;
        margin-top: 0px;
        display: table;
        margin: 0 auto;
        float: none;
        width: 55%;
        &:hover {
            width: 60%;
        }
    }
`;

export const InitialBanner = styled.div`
    width: 50%;
    float: left;
    position: relative;
    margin-left: 2rem;
    top: 70%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;

    @media only screen and (max-width: 900px) {
        top: 90%;
        float: none;
        display: table;
        margin: 0 auto;
        text-align: center;
    }

    @media only screen and (max-width: 700px) {
        top: 88%;
    }

    @media only screen and (max-width: 500px) {
        top: 70%;
    }

    @media only screen and (max-width: 480px) {
        top: 78%;
    }

    @media only screen and (max-width: 450px) {
        top: 75%;
    }

    @media only screen and (max-width: 350px) {
        top: 75%;
    }

    @media only screen and (max-width: 300px) {
        top: 100%;
    }
`;

export const InTitle = styled.h1`
    color: #e8741e;
    font-size: 28px;
`

export const InDescription = styled.div`
    color: black;
    font-size: 16px;
    text-align: justify;
    
    b {
        color: #e8741e;
    }
`

export const InBottom = styled.div`
    @keyframes pulse {
        0% {
            transform: scale(0.95);
        }

        70% {
            transform: scale(3);
        }

        100% {
            transform: scale(0.95);
        }
    }

    display: table;
    margin: 0 auto;
    margin-top: 2rem;
    transform: scale(3);
	animation: pulse 2s infinite;
    color: #e8741e;
`

export const WaveSection = styled.div`
    position: relative;
    background: white;
    z-index: 1;

    @media only screen and (max-width: 900px) {
        margin-top: 45%;
    }

    @media only screen and (max-width: 850px) {
        margin-top: 40%;
    }

    @media only screen and (max-width: 800px) {
        margin-top: 50%;
    }

    @media only screen and (max-width: 750px) {
        margin-top: 60%;
    }

    @media only screen and (max-width: 700px) {
        margin-top: 70%;
    }

    @media only screen and (max-width: 650px) {
        margin-top: 80%;
    }

    @media only screen and (max-width: 600px) {
        margin-top: 90%;
    }

    @media only screen and (max-width: 500px) {
        margin-top: 95%;
    }

    @media only screen and (max-width: 400px) {
        margin-top: 105%;
    }

    @media only screen and (max-width: 370px) {
        margin-top: 110%;
    }

    @media only screen and (max-width: 350px) {
        margin-top: 140%;
    }

    @media only screen and (max-width: 300px) {
        margin-top: 230%;
    }

    @media only screen and (max-width: 250px) {
        margin-top: 300%;
    }
`;

export const WelcomeSection = styled.div`
    width: 100%;
    height: 35rem;
    background-image: url('./backgrounds/background_large.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    font-family: Questrial Regular;
    display: flex;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    @media only screen and (max-width: 2000px) {
        height: 50rem
    }

    @media only screen and (max-width: 1800px) {
        height: 45rem;
    }

    @media only screen and (max-width: 1500px) {
        height: 35rem;
    }

    @media only screen and (max-width: 1200px) {
        height: 40rem;
    }

    @media only screen and (max-width: 900px) {
        height: 30rem;
    }

    @media only screen and (max-width: 600px) {
        height: 35rem;
    }

    @media only screen and (max-width: 500px) {
        height: 40rem;
    }
`;

export const WelcomeBanner = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: linear-gradient(180deg, rgba(80,132,217,1) 20%, rgba(80,132,217,0.27777048319327735) 80%);
    color: white;
    float: right;
    z-index: 2;
`

export const WelcomeText = styled.div`
    position: relative;
    padding: 25.5rem;
    top: 18%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @media only screen and (max-width: 1200px) {
        padding: 5rem;
    }

    @media only screen and (max-width: 900px) {
        padding: 7.5rem;
    }

    @media only screen and (max-width: 600px) {
        padding: 5.5rem;
    }

    @media only screen and (max-width: 500px) {
        padding: 0.5rem;
    }
`

export const WTop = styled.div`
    display: table;
    margin: 0 auto;
    color: black;
    font-size: 12px;
    font-weight: bold;
    width: 10rem;
    background-color: #e8751e;
    color: white;
`

export const WTitle = styled.h1`
    font-size: 28px;
`

export const WDescription = styled.div`
    font-size: 16px;
    text-align: justify;
`

export const MidSection = styled.div`
    width: 100%;
    height: 35rem;

    @media only screen and (max-width: 900px) {
        height: 40rem;
    }

    @media only screen and (max-width: 700px) {
        height: 45rem;
    }

    @media only screen and (max-width: 300px) {
        height: 50rem;
    }
`

export const InfoSection = styled.div`
    height: 100%;
    padding: 1rem;
    margin-top: 3rem;

    h2 {
        color: #e8751e;
    }
`

export const InfoSvg = styled.img`
    width: 10rem;
    margin-bottom: 1rem;
    transition: width 2s;

    &:hover {
        width: 12rem;
    }
`
