import styled from "styled-components";

export const MainAdmin = styled.div`
    width: 100%;
    height: 100%;
`;

export const MidSection = styled.div`
    width: 100%;
    padding-top: 15rem;
    padding-bottom: 15rem;

    
`
