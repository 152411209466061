import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
//import { HashLink as Link } from "react-router-hash-link";
import { MainHeader, MainLogo, LeftBlock, RightBlock, HeadElement, BackgroundSlider } from './components/styleds'; // Aggiungi BackgroundSlider

function Header() {
    const [activeHeadEl, setActiveHeadEl] = useState(0);
    const navigate = useNavigate();

    const changeLocation = (pos) => {
        switch (pos) {
            case 1:
                navigate('/about');
                break;
            case 2:
                navigate('/news');
                break;
            case 3:
                navigate('/contact');
                break;
            default:
                break;
        }

        setActiveHeadEl(pos);
    }

    useEffect(() => {
        setActiveHeadEl(getIndexFromPath());
    })

    const getIndexFromPath = () => {
        const path = window.location.href;
        var directories = path.split("/");
        var lastDirect = directories[(directories.length - 1)];
        return lastDirect ? (lastDirect === 'about' ? 1
            : lastDirect === 'news' ? 2
                : lastDirect === 'contact' ? 3 : 0) : 0;
    }

    const calculateBackgroundRange = (activeElement) => {
        let start = ((activeElement - 1) * 3) * 33.3;

        return { start };
    };

    const reloadPage = () => {
        navigate('/');
    }

    return ( //<Link to="/#info">Chi Siamo</Link>
        <MainHeader>
            <LeftBlock>
                <MainLogo src='./logo.png' onClick={reloadPage} />
            </LeftBlock>
            <RightBlock>
                {activeHeadEl > 0 ? (<BackgroundSlider style={{ transform: `translateX(${calculateBackgroundRange(activeHeadEl).start}%)`, width: `33.3%` }} />) : ''}
                <HeadElement onClick={() => changeLocation(1)} className={activeHeadEl === 1 ? 'active' : ''}>
                    Chi Siamo
                </HeadElement>
                <HeadElement onClick={() => changeLocation(2)} className={activeHeadEl === 2 ? 'active' : ''}>
                    News
                </HeadElement>
                <HeadElement onClick={() => changeLocation(3)} className={activeHeadEl === 3 ? 'active' : ''}>
                    Contattaci
                </HeadElement>
            </RightBlock>
        </MainHeader>
    );
}

export default Header;