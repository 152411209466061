import { MainFooter, MainLogo, FooterDescription } from './components/styleds';

function Footer() {

    const redirectToFBPage = () => {
        window.open('https://www.facebook.com/people/Amici-di-Villa-San-Giuseppe-RC/61556904324710/', '_blank');
    }

    return (
        <MainFooter>
            <MainLogo src='./logo.png' />
            <FooterDescription>
                <div className='mail-banner'><a href='mailto:amicidivillasangiuseppe@gmail.com'>amicidivillasangiuseppe @ gmail.com</a></div>
                <div className='type-banner'>Organizzazione no-profit</div>
                <div className='location-banner'>Via Dei Monti 405 Piazza Umberto I Villa San Giuseppe, Reggio di Calabria, Italy</div>
                <div className='location-banner'>C.F. - 92125470804</div>
                <div className='location-banner'>amicidivsgiuseppe @ namirial.pec</div>
            </FooterDescription>
            <img className='fb-logo' src='./fb_logo.svg' onClick={redirectToFBPage}></img>
        </MainFooter >
    );
}

export default Footer;
