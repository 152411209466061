import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { MainSection, SingleSection, SingleTitle } from './components/styleds';

function About() {
    return (
        <div className="About">
            <Header />
            <MainSection>
                <SingleSection>
                    <SingleTitle>Un'associazione per il borgo!</SingleTitle>
                    <hr></hr>
                    L’Associazione persegue principalmente finalità civiche, solidaristiche e di utilità sociale, nel pieno <b>rispetto</b> della dignità dei propri iscritti, ispirandosi a principi di <b>democrazia, di uguaglianza e di non discriminazione</b> di carattere religioso, politico, etnico, culturale e di sesso, come anche previsto dalla Costituzione attraverso la <b>promozione culturale e civile</b> delle persone ed in particolare di quelle anziane, la ricerca e la costruzione di relazioni <b>concrete e solidali</b> tra le generazioni, la partecipazione attiva alla vita sociale, culturale ed economica della comunità, l’impegno nel volontariato civile e solidale, la <b>tutela</b> del diritto alla salute, all’ambiente, all’assistenza, all’educazione permanente, la non discriminazione a causa dell’età o per qualsiasi altra causa, <b>promozione turistica e culturale</b> della frazione di Villa San Giuseppe e della Vallata del Gallico.
                    I Soci fondatori  e componenti del Direttivo sono:
                    <ul>
                        <li><b>Presidente</b> Sig. Siclari Angelo</li>
                        <li><b>Vice Presidente</b> Sig.ra Richichi Emanuela</li>
                        <li><b>Consigliere</b> Sig. Crupi Filippo Giuseppe</li>
                        <li><b>Consigliere</b> Sig. Gattuso Pasquale</li>
                        <li><b>Consigliere</b> Sig. Scarfò Massimiliano</li>
                        <li><b>Consigliere</b> Sig. Romeo Francescantonio</li>
                        <li><b>Segretario e Tesoriere</b> Sig. Scarpelli Pietro</li>
                    </ul>
                    Delegato ai <b>rapporti con i giovani</b> è Andrea Romeo.
                    Insieme possiamo dare la visibilità che merita al nostro amato Paese, quest’Associazione è di <b>TUTTI</b>.
                </SingleSection>
                <SingleSection className='img-banner'>
                </SingleSection>
            </MainSection>
            <Footer />
        </div>
    );
}

export default About;
