import styled from "styled-components";

export const MainHeader = styled.div`
    position: fixed;
    width: 100%;
    height: 5rem;
    background-color: #fbfbfb;
    color: black;
    padding-right: 2rem;
    z-index: 15;
    box-shadow: 1px 1px 5px 0px #0000001c;
    top: 0;

    @media only screen and (max-width: 600px) {
        display: table;
        padding-bottom: 5px;
    }
`;

export const MainLogo = styled.img`
    width: 70px;
    transition: opacity 0.3s ease;
    margin-top: 0.2rem;

    &:hover {
        cursor: pointer;
        opacity: 0.5;
    }
`;

export const LeftBlock = styled.div`
    margin-left: 1rem;
    float: left;
    font-size: 0.9rem;

    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @media only screen and (max-width: 600px) {
        margin-left: 0;
        float: none;
        top: 0;
        transform: none;
    }
`;

export const RightBlock = styled.div`
    margin-right: 1rem;
    white-space: nowrap;
    float: right;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    @media only screen and (max-width: 600px) {
        display: contents;
        margin: 0 auto;
        top: 0;
        transform: none;
    }
`;

export const BackgroundSlider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #e8751e;
    border-radius: 2px;
    transition: transform 0.3s ease;
    z-index:0;

    @media only screen and (max-width: 600px) {
        display: none;
    }
`;

export const HeadElement = styled.div`
    position: relative;
    display: inline-grid;
    text-align: center;
    width: 5rem;
    height: 1rem;
    font-size: 0.9rem;
    padding: 1rem;
    z-index: 10;

    &:hover {
        cursor: pointer;
        background-color: #ffaec221;
    }

    &.active {
        font-family: Questrial Regular;
        color: white;
    }

    @media only screen and (max-width: 600px) {
        &.active {
            background-color: #e8751e;
            border-radius: 2px;
            transition: transform 0.3s ease;
            font-family: Questrial Regular;
            color: white;
        }
    }
`;