import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { MainAdmin, MidSection } from './components/styleds';
import LoginForm from './components/LoginForm';
import AdminPanel from './components/AdminPanel';

function Admin() {
    const [attempts, setAttempts] = useState(0);
    const [loginError, setLoginError] = useState(null);
    const [isLogged, setIsLogged] = useState(false);

    useEffect(() => {
        console.log(isLogged);
        setIsLogged(false);
    }, []);

    const setIsLoggedField = (cond) => {
        console.log(cond)
        setIsLogged(cond);
    }

    const submitLogin = async (username, password) => {
        if (attempts >= 5) {
            setLoginError('Troppi tentativi di login. Riprova più tardi.');
            return;
        }

        try {
            const response = await fetch('./controller/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                throw new Error('Errore durante la richiesta di login');
            }

            const data = await response.json();

            if (data.success) {
                console.log('Login avvenuto con successo');
                setIsLogged(true);
                setAttempts(0); // Resetta i tentativi in caso di successo
                // Puoi reindirizzare l'utente o salvare il token
            } else {
                setIsLogged(false);
                setLoginError(data.message);
                setAttempts(prev => prev + 1); // Incrementa i tentativi falliti
            }
        } catch (error) {
            console.error('Errore durante il login:', error);
            setLoginError('Si è verificato un errore. Riprova più tardi.');
        }
    };

    return (
        <MainAdmin>
            <Header />
            {!isLogged ? (
                <LoginForm
                    submit={submitLogin}
                    loginError={loginError}
                />
            ) : (
                <AdminPanel
                    setIsLogged={setIsLoggedField}
                />
            )}
            <Footer />
        </MainAdmin>
    );
}

export default Admin;
