import styled from "styled-components";

export const NewsContainer = styled.div`
    margin-top: 10rem;

    @media only screen and (max-width: 250px) {
        margin-top: 15rem;
    }

    @media only screen and (max-width: 355px) {
        margin-top: 13rem;
    }
`;

export const NewsCard = styled.div`
    display: table;
    margin: 0 auto;
    width: 50%;
    height: 30rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    background-color: #f7f7f7;
    border-radius: 15px;
    box-shadow: 0 6px 10px -2px #80808047;
`;

export const ImageSection = styled.div`
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    width: 100%;
    height: 50%;
    background-size: cover;
    background-position: center;
`;

export const TextSection = styled.div`
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 100%;
    height: 50%;

    color: #2c343e;

    a {
        color: #e8751e;
        text-decoration: none;
        font-weight: 600;
    }

    .top {
        margin: 0.5rem;
        margin-top: 1rem;
        font-weight: 600;
    }

    .mid {
        margin: 0.5rem;
        font-weight: 100;
    }

    @media only screen and (max-width: 355px) {
        .top {
            font-size: 12px !important;
        }

        .mid  {
            font-size: 10px !important;
        }
    }

    @media only screen and (max-width: 555px) {
        .top {
            font-size: 16px !important;
        }

        .mid{
            font-size: 13px !important;
        }
    }

    @media only screen and (max-width: 855px) {
        .top {
            font-size: 20px !important;
        }

        .mid {
            font-size: 15px !important;
        }
    }
`;
