import React, { useState, useEffect } from 'react';
import { MainSection } from './components/styleds';
import {
    Button,
    Flex,
    Stack
} from "@chakra-ui/react";

function AdminPanel({ setIsLogged }) {
    useEffect(() => {

    }, []);

    const logout = () => {
        setIsLogged(false);
    }

    return (
        <MainSection>
            <Flex
                flexDirection="column"
                width="100wh"
                height="100vh"
                backgroundColor="#ffd9aa"
                justifyContent="center"
                alignItems="center"
            >
                <Stack
                    flexDir="column"
                    mb="2"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Button
                        borderRadius={0}
                        type="submit"
                        variant="solid"
                        colorScheme="orange"
                        bg="orange.300"
                        _hover={{ bg: "orange.400" }}
                        width="full"
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </Stack>
            </Flex>
        </MainSection>
    );
}

export default AdminPanel;
