import styled from "styled-components";

export const MainSection = styled.div`
    width: 100%;
    padding-top: 7rem;

    .img-banner {
        background-image: url('./backgrounds/bn0.jpeg');
        background-size: cover;

        @media only screen and (max-width: 1100px) {
            width: 100%;
        }
    }

    a {
        color: #e8751e;
        text-decoration: none;
    }

    hr {
        border-top: 1px solid #e8751e;
        width: 95%;
    }
`

export const SingleSection = styled.div`
    display: table-cell;
    width: 45%;
    padding: 3rem;
    height: 25rem;
    text-align: justify;

    @media only screen and (max-width: 1100px) {
        display: inline-block;
        padding: 0;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 850px) {
        margin-top: 2rem;
        padding: 0;
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 750px) {
        margin-top: 2rem;
        padding: 0;
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 500px) {
        margin-top: 2rem;
        padding: 0;
        margin-bottom: 2rem;
    }

    @media only screen and (max-width: 345px) {
        margin-top: 5rem;
    }
`

export const SingleTitle = styled.h1`
    color: #e8751e;

    @media only screen and (max-width: 500px) {
        font-size: 25px;
    }

    @media only screen and (max-width: 430px) {
        font-size: 20px;
    }

    @media only screen and (max-width: 300px) {
        font-size: 15px;
    }
`