import Footer from '../../components/Footer';
import Header from '../../components/Header';
import React, { useState, useEffect } from 'react';
import { NewsContainer, NewsCard, ImageSection, TextSection } from './components/styleds';

function News() {
    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await fetch('./controller/login.php?utext=admin&ptext=AccountPass$.1'); // Assicurati che l'URL sia corretto
                if (!response.ok) {
                    throw new Error('Errore durante la richiesta delle news');
                }
                const data = await response.json();
                console.error(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchNews();
    }, [])

    return (
        <div className="News">
            <Header />
            <NewsContainer>
                <NewsCard>
                    <ImageSection style={{ backgroundImage: 'url(backgrounds/500news.jpeg)' }}></ImageSection>
                    <TextSection>
                        <div className='top'>Unisciti al raduno di Fiat 500 a Villa San Giuseppe</div>
                        <div className='mid'>Pomeriggio di festa con le splendide FIAT 500, Domenica 28 Aprile, unisciti <a href='https://www.facebook.com/events/841360894418659/?acontext=%7B%22event_action_history%22%3A[]%7D' target='_blank'>cliccando qui</a>!</div>
                    </TextSection>
                </NewsCard>
                <NewsCard>
                    <ImageSection style={{ backgroundImage: 'url(backgrounds/newspaper.jpeg)' }}></ImageSection>
                    <TextSection>
                        <div className='top'>Articolo di Strettoweb sull'associazione</div>
                        <div className='mid'><a href='https://www.strettoweb.com/2024/03/reggio-calabria-nasce-lassociazione-amici-di-villa-san-giuseppe/1685505/' target='_blank'>Cliccando qui</a> è possibile leggere l'articolo di Strettoweb dedicato all'associazione</div>
                    </TextSection>
                </NewsCard>
            </NewsContainer>
            <Footer />
        </div>
    );
}

export default News;
