import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import News from './pages/News';
import Home from './pages/Home';
import Contact from './pages/Contact';
import About from './pages/About';
import Admin from './pages/Admin';

import { ChakraProvider } from '@chakra-ui/react'

function App() {
  const navigate = useNavigate();

  return (
    <ChakraProvider>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/news" element={<News />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/admin" element={<Admin />} />
        </Routes>
      </div>
    </ChakraProvider>
  );
}

export default App;
