import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { MainHome, TopBackground, BgBanner, BgTitle, BgTitle2, InitialSection, InitialSvg, WaveSection, WelcomeSection, WelcomeBanner, WelcomeText, MapsSection, InitialBanner, InTitle, InDescription, InBottom, WTop, WTitle, WDescription, MidSection, InfoSvg, InfoSection } from './components/styleds';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

function Home() {
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const position = { lat: 38.18972047315104, lng: 15.675794175134843 };

    const MapTypeId = {
        HYBRID: 'hybrid',
        ROADMAP: 'roadmap',
        SATELLITE: 'satellite',
        TERRAIN: 'terrain'
    };
    const gTheme = {
        id: 'terrain',
        label: 'Terrain (no mapId)',
        mapTypeId: MapTypeId.TERRAIN
    };

    useEffect(() => {
        function handleScroll() {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop > 300);
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const renderSlides = () => {
        const slides = []
        for (let i = 0; i < 19; i++) {
            slides.push((
                <SplideSlide key={i} onClick={() => openFullscreenImage(i)}>
                    <img src={'backgrounds/bn' + i + '.jpeg'} alt={'Image' + i} width={'80%'} />
                </SplideSlide>
            ))
        }
        return slides;
    }

    const openFullscreenImage = (index) => {
        setFullscreenImage(index);
    }

    const closeFullscreenImage = () => {
        setFullscreenImage(null);
    }

    return (
        <MainHome>
            <Header />
            <TopBackground>
                {!isScrolled && (<video style={{ width: '100%' }} id="background_video" autoPlay loop muted>
                    <source type='video/mp4' src='./backgrounds/villasg.mp4' />
                </video>)}
                <BgBanner>
                    <BgTitle>Gli Amici di</BgTitle>
                    <BgTitle2>Villa San Giuseppe</BgTitle2>
                </BgBanner>
            </TopBackground>
            <InitialSection>
                <InitialSvg src='./village.svg' />
                <InitialBanner>
                    <InTitle>Villa San Giuseppe,</InTitle>
                    <hr></hr>
                    <InDescription>un borgo delizioso e gradevole da visitare, un angolo stretto tra l’<b>Aspromonte</b> e il <b>Tirreno</b>, dove riscoprire la quiete con panorami indimenticabili tra agrumeti dai colori variegati. Situato in un’area compresa tra le fiumare del <b>Gallico</b> e del <b>Catona</b>, due corsi d’acqua che dall’Aspromonte scendono fino allo  <b>Stretto di Messina</b>. Chi ama la natura qui trova stupendi sentieri per semplici passeggiate e paesaggi di rara bellezza, dai colori intensi e dominati dai profumi regalati dalla natura, come la zagara d’arancio.
                        Vanta una gastronomia ricca e deliziosa. Di alta qualità anche il miele e il vino prodotto nell’<b>Altopiano di Arghillà</b>.
                        Un’esclusiva di questo territorio sono le <b>Arance Belladonna</b> e <b>Biondo tardivo</b> di San Giuseppe.
                        Frutti dal sapore dolce e zuccherino, per la loro dolcezza le <b>arance</b> sono impiegate per produrre succhi da bere, marmellate e produzioni dolciarie varie, come le <b>scorzette candite</b>.</InDescription>
                    <InBottom><KeyboardArrowDownIcon /></InBottom>
                </InitialBanner>
            </InitialSection>
            <WaveSection>
                <div className='box'></div>
                <WelcomeSection>
                    <WelcomeBanner id={'#info'}>
                        <WelcomeText>
                            <WTop>Fondata a Febbraio 2024</WTop>
                            <WTitle>L'Associazione</WTitle>
                            <hr></hr>
                            <WDescription>Questa associazione persegue principalmente finalità civiche, solidaristiche e di utilità sociale, nel pieno rispetto della dignità dei propri iscritti, ispirandosi a principi di democrazia, di uguaglianza e di non discriminazione di carattere religioso, politico, etnico, culturale e di sesso, come anche previsto dalla Costituzione.</WDescription>
                        </WelcomeText>
                    </WelcomeBanner>
                </WelcomeSection>
            </WaveSection>
            <MapsSection>
                <APIProvider apiKey={'AIzaSyDqD8KHXQEGTXb0fvC72GHrlRGNBc_gZps'}>
                    <Map
                        defaultCenter={position}
                        defaultZoom={12}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                        mapId={gTheme.mapId || null}
                        mapTypeId={gTheme.mapTypeId}
                        styles={{ gTheme }}
                    >
                        <Marker position={position} />
                    </Map>
                </APIProvider>
            </MapsSection>
            <MidSection>
                <InfoSection>
                    <InfoSvg src='./members.svg' />
                    <h2>I Soci fondatori  e componenti del Direttivo sono:</h2>
                    <p>- Presidente Sig. <b>Siclari Angelo</b></p>
                    <p>- Vice Presidente Sig.ra <b>Richichi Emanuela</b></p>
                    <p>- Consigliere Sig. <b>Crupi Filippo Giuseppe</b></p>
                    <p>- Consigliere Sig. <b>Gattuso Pasquale</b></p>
                    <p>- Consigliere Sig. <b>Scarfò Massimiliano</b></p>
                    <p>- Consigliere Sig. <b>Romeo Francescantonio</b></p>
                    <p>- Segretario e Tesoriere <b>Sig. Scarpelli Pietro</b></p>
                </InfoSection>
            </MidSection>
            <Splide options={{ perPage: 1 }}>
                {renderSlides()}
            </Splide>
            {fullscreenImage !== null && (
                <div className="fullscreen-overlay" onClick={closeFullscreenImage}>
                    <img src={'backgrounds/bn' + fullscreenImage + '.jpeg'} alt={'Image' + fullscreenImage} className="fullscreen-image" />
                </div>
            )}
            <Footer />
        </MainHome >
    );
}

export default Home;
